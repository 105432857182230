<template>
    <div>
        <div v-if="icon === 'pdf'">
            <span
                class="clickable-icon"
                @click="$emit('callback')">
                <icon
                    name="pdf"
                    size="20" />
            </span>
        </div>
        <div v-else>
            <span
                v-if="canEdit"
                class="clickable-icon"
                @click="openTemplate">
                <icon
                    name="edit"
                    size="20" />
            </span>
            <span v-else>
                <icon
                    name="lock"
                    size="20" />
            </span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapGetters } from 'vuex';

export default {
    components: {
        Icon
    },
    props: {
        item: {
            type: Object,
            required: true
        },
        icon: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapGetters([
            'userIsAdmin',
            'currentUser',
        ]),
        canEdit() {
            return this.userIsAdmin || this.currentUser.agency.id == this.item.agencyId;
        }
    },
    methods: {
        openTemplate() {
            this.$router.push({ name: 'report-builder', params: { template_id: this.item.id } });
        }
    }
};
</script>

<style lang="scss" scoped>
.clickable-icon {
    cursor: pointer;
}
</style>